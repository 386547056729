"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomePage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var products_1 = require("../../api/calls/product/products");
var categories_1 = require("../../api/calls/product/categories");
var selection_1 = require("../../reducers/slices/selection");
var react_router_dom_1 = require("react-router-dom");
var recently_viewed_1 = require("../../api/calls/product/recently_viewed");
var add_product_thunk_1 = require("../../reducers/slices/basket/thunks/add_product_thunk");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var constants_1 = require("./shop/constants");
var google_tags_1 = require("../../helpers/google_tags");
var calculateDiscountPercentage_1 = require("../../helpers/calculateDiscountPercentage");
var HomePage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    // const { products } = useSelector<any, IBasketSliceState>((state) => state.basketState);
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _a = (0, react_1.useState)([]), allProducts = _a[0], setAllProducts = _a[1];
    var _b = (0, react_1.useState)([]), categories = _b[0], setCategories = _b[1];
    var _c = (0, react_1.useState)([]), contentSliders = _c[0], setContentSliders = _c[1];
    var _d = (0, react_1.useState)([]), recentlyViewed = _d[0], setRecentlyViewed = _d[1];
    var _e = (0, react_1.useState)(1), currentPage = _e[0], setCurrentPage = _e[1];
    var _f = (0, react_1.useState)(0), totalRecords = _f[0], setTotalRecords = _f[1];
    var _g = (0, react_1.useState)(20), recordsPerPage = _g[0], setRecordsPerPage = _g[1];
    (0, react_1.useEffect)(function () {
        var _a;
        if (user.user._id) {
            (0, recently_viewed_1.getRecentlyViewedProducts)({
                user_id: (_a = user.user._id) !== null && _a !== void 0 ? _a : "",
            }).then(function (response) {
                var _a;
                console.log("Recent products fetched", response);
                setRecentlyViewed((_a = response.content) !== null && _a !== void 0 ? _a : []);
            });
        }
    }, [user]);
    (0, react_1.useEffect)(function () {
        (0, categories_1.getCategories)({
            page: "1",
        }).then(function (response) {
            var success = response.success;
            // const cunstomProductCategories: any = [{ image_url: Banner1 }, { image_url: Banner2 }];
            if (success) {
                var product_category_list = response.content.product_category_list;
                var size = 5;
                var filtered_list = product_category_list.filter(function (category) { return category.image_url; }); //.slice(0, size);
                // filtered_list.unshift({ image_url: Banner1 }, { image_url: Banner2 }, { image_url: Banner3 });
                setCategories(filtered_list);
            }
            // else {
            //   setCategories(cunstomProductCategories);
            // }
        });
        (0, products_1.getProducts)({
            page: currentPage.toString(),
            size: recordsPerPage.toString(),
            on_promotion: true,
        }).then(function (response) {
            console.log("products response", response);
            setAllProducts(response.content.product_list);
            setTotalRecords(response.content.count);
        });
    }, [currentPage]);
    (0, react_1.useEffect)(function () {
        var sortedCategoryList = [];
        var _loop_1 = function (index) {
            var filteredCategory = categories[index];
            sortedCategoryList.push({
                content: (react_1.default.createElement("div", { className: "flex cursor-pointer" //justify-center items-end pb-10
                    , onClick: function () {
                        var path = "/shop";
                        var _id = filteredCategory._id, name = filteredCategory.name, description = filteredCategory.description;
                        if (_id && name && description != "advert") {
                            dispatch((0, selection_1.setSelectedCategory)({ item: filteredCategory, level: 0 }));
                            navigate("".concat(path, "/category/").concat(0, "/").concat(filteredCategory._id, "/").concat(encodeURI(filteredCategory.name)));
                        }
                        else {
                            navigate(path);
                        }
                    } },
                    react_1.default.createElement("img", { className: "w-full h-full relative top-0 left-0", src: "".concat(filteredCategory.image_url) }),
                    filteredCategory.name && filteredCategory.description != "advert" && (react_1.default.createElement("div", { className: "relative\n            top-4 left-4 \n            flex items-center\n            p-4 h-[50px] \n            bg-black/20 shadow-md shadow-black/75\n            rounded-lg" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: filteredCategory.name, textColor: "text-white", size: "2xlarge", bold: true }))))),
            });
        };
        for (var index = 0; index < categories.length; index++) {
            _loop_1(index);
        }
        setContentSliders(sortedCategoryList);
    }, [categories]);
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, null,
            react_1.default.createElement("div", { className: "flex items-center cursor-pointer", onClick: function () { return window.open(process.env.GOOGLE_MAPS_STORE_LOCATION, "_blank"); } },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Location Address: 84 Rietfontein Rd, Primrose, Germiston " //
                    , size: "large", textColor: "text-black" }),
                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_light_svg_icons_1.faLocationDot, borderColor: "border-transparent" //
                    , iconColor: "text-primary", onClick: function () { return window.open(process.env.GOOGLE_MAPS_STORE_LOCATION, "_blank"); } }))),
        contentSliders && contentSliders.length > 0 && (react_1.default.createElement(doshx_controls_web_1.ContentCarouselControl
        //
        , { 
            //
            items: contentSliders, heightClasses: "h-[350px]", colors: {
                bulletColor: "bg-primary",
            }, slideTimeout: 3700, breakpoints: {
                sm: {
                    viewport: 1,
                },
                md: {
                    viewport: 2,
                },
                lg: {
                    viewport: 2,
                },
                xl: {
                    viewport: 3,
                },
                xxl: {
                    viewport: 4,
                },
            } })),
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-8" },
            react_1.default.createElement(doshx_controls_web_1.ProductsBigControl, { category: { name: "On Promotion", color: "text-error" }, colors: {
                    discount: {
                        textColor: "text-white",
                        // iconColor: `text-primary`,
                        amountColor: "text-primary",
                        backgroundColor: "bg-primary",
                    },
                }, products: allProducts.map(function (product, index) {
                    return {
                        index: index,
                        _id: product._id,
                        image: product.image_url,
                        title: product.title,
                        amount: product.price,
                        rating: product.rating.average,
                        borderColor: "border-white",
                        shadowColor: "shadow-primary",
                        // borderColor: "border-borders-light",
                        // shadowColor: "shadow-shadows-dark/5",
                        textColor: "text-primary",
                        amountColor: product.on_promotion ? "text-disabled" : "text-primary",
                        // starActiveColor: `text-borders-light`,
                        // starInactiveColor: `text-borders-light`,
                        loaderColor: "text-primary",
                        backgroundColor: "bg-white",
                        discountLabel: (0, calculateDiscountPercentage_1.generateDiscountLabel)(product.on_promotion, product.price, product.promotion_price),
                        discountAmount: product.on_promotion ? product.promotion_price : "",
                        outOfStock: product.status == constants_1.OUT_OF_STOCK,
                    };
                }), onClick: function (product) {
                    console.log("product", product);
                    // Google Tag Event - Click Product
                    (0, google_tags_1.trackProductClick)({
                        products: [
                            {
                                title: product.title,
                                _id: product._id,
                                price: product.amount,
                                quantity: product.quantity,
                                sku: "",
                            },
                        ],
                        _id: product._id,
                        price: product.amount,
                    });
                    dispatch((0, selection_1.setSelectedProduct)(product));
                    navigate("/product/".concat(product._id));
                }, pagination: {
                    currentPage: currentPage,
                    totalRecords: totalRecords,
                    recordsPerPage: recordsPerPage,
                    onClick: function (pageNumber) {
                        setCurrentPage(pageNumber);
                    },
                }, button: {
                    label: "Add to Cart",
                    borderColor: "border-primary",
                    onClick: function (product) {
                        dispatch((0, add_product_thunk_1.add_product_thunk)({
                            product: product,
                            quantity: 1,
                        }));
                    },
                } }))));
};
exports.HomePage = HomePage;
